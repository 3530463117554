@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900');

body {
  margin: 0;
  font-family: 'Figtree', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

html, body {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, #root, .main-wrapper {
  height: 100vh;
}

.carousel-caption {
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.carousel-control-prev, .carousel-control-next {
  opacity: 0;
}
.carousel-control-prev:hover, .carousel-control-next:hover {
  opacity: 1;
}

.owl-item {
  height: 100% !important;
  border-radius: 20%;
}

.text-on-image {
  position: relative;
}

.text {
  position: absolute;
  bottom: 0;
  left: 0;
  display: none;
}

.text-active{
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  background: white;
  width: 100%;
  height: 20%;
}

.text-on-image:hover .text {
  display: block;
  background-image: linear-gradient(to bottom,transparent, black);
  width: 100%;
  height: 30%;
}

.form-control:placeholder-shown {
  box-shadow: none !important;
  border: 0 !important;
}

.bg-black {
  background: black !important;
}

.logo a:hover {
  color: #f53436 !important;
  text-decoration: none !important;
}

.navbar-brand:hover {
  color: #f53436 !important;
  text-decoration: none !important;
}

.text-decoration-none:hover {
  text-decoration: none !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

.text-dark-blue {
  color: #090970 !important;
}

.nav-link {
  color: #090970 !important;
}

.video-card {
  margin: 0.2rem !important;
}


.video-card:hover {
  margin: 0 !important;
}


.overflow-y::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

/* Track */
.overflow-y::-webkit-scrollbar-thumb {
  background: #c3c1c1;
  border-radius: 10px;
}

a.text-decoration-none:hover {
  text-decoration: underline !important;
}

.owl-nav, .owl-dots {
  margin: 0 !important;
}

.card-img {
  width: 100%;
  height: 25vw;
  object-fit: cover;
}

#promo-video, #promo-video .video-js {
  position:relative;
  z-index:0;
  width: 100vw !important;
  height: 80vh !important;
  cursor: pointer;
}

.overlay {
  position:absolute;
  top:0;
  left:0;
  z-index:1;
  padding-right: 10vw;
}

.bs-scroll {
  --bs-scroll-height: 100px !important;
}

.navbar-toggler {
  border: 2px white !important;
  color: white !important;
}

button .navbar-toggler::before, button .navbar-toggler::after {
  box-sizing: initial !important;
}

.nav-link {
  color: #FFFFFF !important;
  font-weight: bold !important;
  font-size: 1rem !important;
}
.nav-link:hover {
  color: #f53436 !important;
  font-weight: bold !important;
  font-size: 1rem !important;
}
.search-wrap-2 {
  max-width: 340px !important;
}

.nav-font {
  font-size: 1rem !important;
}

.btn-white {
  color: black !important;
  background: white !important;
}

.font-lg {
  font-size: 1.6rem;
}
.font-md {
  font-size: 1.3rem;
}

.font-sm {
  font-size: 1rem;
}

table tr td {
  vertical-align: top !important;
  font-size: 1rem !important;
}

.footer-border-top {
  border-top: 1px solid #444;
}

.text-gray {
  color:  #444 !important;
}
.text-gray-light {
  color: #a9a6a6 !important;
}

.partners-img {
  vertical-align: middle;
  max-height: 180px;
}

.partners-item {
  max-height: 200px;
}

@media only screen and (max-width: 800px) {
  .card-img {
    height: 50vh;
    object-fit: cover;
  }
  #promo-video, #promo-video .video-js {
    height: 40vh !important;
  }
  .border-end {
    border: 0 !important;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1000px) {
  .card-img {
    height: 65vh;
    object-fit: cover;
  }
  .border-end {
    border: 0 !important;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1400px) {
  .card-img {
    height: 65vh;
    object-fit: cover;
  }
}
